.about-us {
  display: flex;
  justify-content: center;
  padding: 50px 0;

  @media (max-width: 767px) {
    padding: 30px 0;
  }

  .about-us-wrapper {
    padding: 50px 50px;
    width: 80vw;
    background-color: white;

    @media (max-width: 600px) {
      width: 90vw;
      padding: 40px 5px;
    }

    .about-us-title {
      font-size: 45px;
      font-weight: 700;
      text-align: center;

      @media (max-width: 767px) {
        font-size: 35px;
      }

      @media (max-width: 480px) {
        font-size: 28px;
      }
    }
    .about-us-explain {
      font-size: 13px;
      color: rgb(65, 64, 64);
      margin-top: 5px;
      text-align: center;
      margin-bottom: 25px;
    }
    .about-us-top {
      background-color: #ffc7d778;
      padding: 0;
      width: 100%;
      text-align: center;
      border-radius: 10px;
      margin-bottom: 30px;
      @media (max-width: 480px) {
        margin-bottom: 15px;

      }

      .about-us-card-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;

        @media (max-width: 1300px) {
          flex-direction: column;
        }

        .about-us-card {
          padding: 15px;
          flex: 1;
          border-radius: 10px;
          @media (max-width: 1300px) {
            padding-bottom: 0;
          }
          @media (max-width: 767px) {
            padding-bottom: 0;
          }

          @media (max-width: 480px) {
            padding: 15px;
            padding-bottom: 0;
          }
          .about-us-item {
            z-index: 1;
            padding: 15px;
            height: 100%;
            background-color: white;
            text-align: center;
            color: rgb(91, 90, 90);
            font-weight: 400;
            font-size: 13px;
            border-radius: 10px;
            .title {
              font-size: 20px;
              font-weight: 600;
              margin-bottom: 30px;
              color: black;
            }
          }
        }
        .about-us-card-last-child{
          @media (max-width: 1300px) {
            padding-bottom: 25px;
          }
          @media (max-width: 767px) {
            padding-bottom: 25px;
          }

          @media (max-width: 480px) {
            padding-bottom: 15px;
          }
        }
      }
    }
  }
}
