.upper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .icon-wrapper {
    height: 70px;
    width: 70px;
    margin-top: 10px;
    border-radius: 50%;
    border: 2px solid black;
    display: flex;
    justify-content: center;
    align-items: center;

    .company-info-icon {
      height: 35px;
      width: 35px;
      color: black;
    }
  }
  .text {
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: 10px;
    font-weight: 700;
    font-size: 14px;
    p {
      margin: 0;
      padding: 0;
    }
  }
}
.lower {
  font-weight: 700;
  text-align: center;

  .words {
    color: rgb(65, 64, 64);
    padding: 25px 0;
  }
  .company-info-text {
    text-decoration: none;
    color: #000;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
  }
}

.contact-us-social {
  background-color: #ffc7d778;
  padding: 20px;
  color: black;
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 10px;

  .social-lazada-img {
    width: 50px;
    height: 50px;
    padding: 2px 2px;
    image-rendering: optimizeQuality;

    @media (max-width: 480px) {
      width: 40px;
      height: 40px;
      padding: 1px 1px;
    }
  }
  .social-shopee-img {
    width: 50px;
    height: 50px;
    padding: 2px 4px 5px 3px;
    image-rendering: optimizeQuality;

    @media (max-width: 480px) {
      width: 40px;
      height: 40px;
      padding: 1px 2px 3px 2px;
    }
  }

  .social-link {
    text-decoration: none;
    color: black;
    margin: 0 15px;
    @media (max-width: 1300px) {
      margin: 0 11px;
    }
    @media (max-width: 767px) {
      margin: 0 6px;
    }

    @media (max-width: 480px) {
      margin: 0 4px;
    }

    .social-icon {
      font-size: 50px;

      @media (max-width: 480px) {
        font-size: 40px;
      }
    }
  }
}
